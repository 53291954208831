// Note: Requires flexslider 2, buddy.
jQuery(document).ready(function($) {
	$('.slider-wrapper .flexslider').flexslider({
        animation: "fade",
        directionNav: false,
		manualControls: ".overlay-text-slider-wrapper .flex-control-nav li button",
        pauseOnAction: true, // default setting
		  after: function(slider) {
		    /* auto-restart player if paused after action */
		    if (!slider.playing) {
		      slider.play();
		    }
		  }
    });

}); /* end of as page load scripts */